<template>
    <div>
        <CCard class="zw-page">
            <CCardBody>
                <zw-table ref="table"
                          tableName="shipments"
                          base-table="offering"
                          columnsPrefix="shipments.column."
                          :items="myProvider"
                          :fields="fields"
                          :filter="filter"
                          :selected="selected"
                          :selectedSource="selectedSource"
                          title="shipments.title.shipments"
                          :actions-list="getActions()"
                >
                    <template v-slot:top-actions>
                        <b-form inline>
                            <font-awesome-icon
                                role="button"
                                icon="arrow-left"
                                @click="prevMonth()"/>&nbsp;
                            <b-form-select
                                v-model="month"
                                :options="monthsList()"
                                class="mr-2"
                                @change="monthChanged"
                            ></b-form-select>
                            <font-awesome-icon
                                role="button"
                                icon="arrow-right"
                                @click="nextMonth()"/>
                            <b-form-select
                                v-model="shippingMethod"
                                :options="shippingMethods"
                                @change="shippingMethodChanged"
                                class="mr-2 ml-2"
                            ></b-form-select>

                            <b-form-checkbox
                                v-model="shipped"
                                switch
                                value="1"
                                unchecked-value="0"
                                @change="shippedChanged"
                            >{{ $t('shipments.column.shipped') }}
                            </b-form-checkbox>
                        </b-form>
                    </template>

                    <template #cell(selected)="data">
                        <div>
                            <b-form-checkbox :key="data.item.id"
                                             v-if="data.item.selectable && !['all','filtered'].includes(selectedSource)"
                                             @change="selectRow($event, data.item)"
                                             :checked="selected.includes(data.item)"
                            ></b-form-checkbox>
                        </div>
                    </template>
                </zw-table>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'
import tableDateFilter from '@/bundles/erika_common_bundle/mixins/table-date-filter'
import moment from "moment";

export default {
    name: 'Shipments',
    mixins: [commonTable, tableDateFilter],
    data() {
        return {
            tableOptions: {
                sortBy: null,
                perPage: 100,
                sortDesc: false,
            },
            shipped: false,
            shippingMethod: 'dhl',
            shippingMethods: [
                {text: 'All', value: 'all'},
                {text: 'Other', value: 'other'},
                {text: 'DHL', value: 'dhl'},
                {text: 'DHL International', value: 'dhl_int'},
                {text: 'DHL Express', value: 'dhl_express'},
                {text: 'Warenpost', value: 'warenpost'},
                {text: 'Warenpost International', value: 'warenpost_int'},
                {text: 'Briefsendung', value: 'briefsendung'},
            ],
            combineFile: null,
            combineFile2: null,
            saveFilters: true,
        }
    },
    methods: {
        ...mapGetters('Shipments', ['getShipmentsTable']),
        myProvider(ctx) {
            return this.commonProviderFetch(
                ctx,
                'Shipments/fetchShipments',
                'getShipmentsTable',
                {'combineFile': 'combine_merge_file', 'combineFile2': 'combine_merge_file_2'},
                (tableData) => {
                    if (tableData.params.filter.shipping_method) {
                        this.shippingMethod = tableData.params.filter.shipping_method
                    }
                    if (tableData.params.filter.shipped) {
                        this.shipped = tableData.params.filter.shipped
                    }
                }
            )
        },
        ctxAdditionalParams() {
            return {
                'shipping_method':  this.shippingMethod,
                'shipped':  this.shipped,
            }
        },
        sync() {
            this.loading = true
            let list = this.selected.map(row => row.id)
            this.$store.dispatch('Shipments/sync', list)
                .then((response) => {
                    if (response.data.batch_group) {
                        this.$root.$children[0].showJobsToast(response.data.batch_group)
                    }
                    this.$root.showMessage(
                        this.$t('common.form.title.success'),
                        this.$t('multichannel.text.queued_count', {count: response.data.count}),
                        'success'
                    )
                })
                .finally(() => {
                    this.loading = false
                })
        },
        sendTrackingInfo() {
            this.loading = true
            let list = this.selected.map(row => row.id)
            this.$store.dispatch('Shipments/sendTrackingInfo', list)
                .then((response) => {
                    if (response.data.batch_group) {
                        this.$root.$children[0].showJobsToast(response.data.batch_group)
                    }
                    this.$root.showMessage(
                        this.$t('common.form.title.success'),
                        this.$t('multichannel.text.queued_count', {count: response.data.count}),
                        'success'
                    )
                })
                .finally(() => {
                    this.loading = false
                })
        },
        createCombineFiles() {
            this.loading = true
            let list = this.selected.map(row => row.id)
            this.$store.dispatch('Invoice/generateCombine', list)
                .then((response) => {
                    if (response.data.batch_group) {
                        this.$root.$children[0].showJobsToast(response.data.batch_group)
                    }
                    this.$root.showMessage(
                        this.$t('common.form.title.success'),
                        this.$t('multichannel.text.queued_count', {count: response.data.count}),
                        'success'
                    )
                })
                .finally(() => {
                    this.loading = false
                })
        },
        downloadMergerCombineFile() {
            this.$root.downloadDocument('merged_combine')
        },
        downloadMergerCombineFile2() {
            this.$root.downloadDocument('merged_combine_2')
        },
        openChangeAttribute() {
            this.filter['shipping_method'] = this.shippingMethod
            this.filter['shipped'] = this.shipped

            this.$root.$children[0].openModal('change-attribute-modal', {
                type: 'offering',
                selectedData: this.getSelectedData('shipments'),
            }, this.refreshTable, {width: '800px'})
        },
        getActions() {
            return [
                {
                    title: this.$t('common.button.mass_update'),
                    icon: 'list',
                    disabled: !(this.selected.length || this.selectedSource != 'selected'),
                    hide: !this.$root.hasAccess('sales', 'update'),
                    click: () => {
                        this.openChangeAttribute()
                    }
                },
                {
                    title: this.$t('multichannel.button.sync'),
                    icon: 'cloud-download-alt',
                    disabled: !this.selected.length,
                    click: () => {
                        this.sync()
                    }
                },
                {
                    title: this.$t('multichannel.button.send_tracking_info'),
                    icon: 'paper-plane',
                    disabled: !this.selected.length,
                    click: () => {
                        this.sendTrackingInfo()
                    }
                },
                {
                    title: this.$t('shipments.button.combine'),
                    icon: 'puzzle-piece',
                    disabled: !this.selected.length,
                    click: () => {
                        this.createCombineFiles()
                    }
                },
                {
                    title: this.$t('shipments.button.download_merged_combine_file'),
                    icon: 'paperclip',
                    hide: !this.combineFile,
                    click: () => {
                        this.downloadMergerCombineFile()
                    }
                },
                {
                    title: this.$t('shipments.button.download_merged_combine_file_2'),
                    icon: 'paperclip',
                    hide: !this.combineFile2,
                    click: () => {
                        this.downloadMergerCombineFile2()
                    }
                },
            ]
        },
        shippingMethodChanged(val) {
            this.$set(this.filter, 'shipping_method', val)
            this.$set(this.filter, 'filterChanged', true)
        },
        shippedChanged(val) {
            this.$set(this.filter, 'shipped', val)
            this.$set(this.filter, 'filterChanged', true)
        }
    },
}
</script>